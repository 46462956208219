import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
import 'material-icons/iconfont/material-icons.css';
import router from "./routes"
import axios from 'axios'
import {createClient} from '@sanity/client'

axios.defaults.baseURL = '#';
axios.defaults.withCredentials = true;

const client = createClient({
    projectId: 'l389ox8a',
    dataset: 'production',
    useCdn: true, // set to `false` to bypass the edge cache
    apiVersion: '2024-11-10', // use current date (YYYY-MM-DD) to target the latest API version
    // token: process.env.SANITY_SECRET_TOKEN // Needed for certain operations like updating content or accessing previewDrafts perspective
})

const app = createApp(App)
app.use(router)
app.use(client)
app.mount('#app')
