<template>
    <div @click="this.$emit('showchat')" class="chat_bobble p-3 d-flex justify-content-center align-items-center">
        <i class="material-icons-round text-light cursor show-not-onhover">perm_phone_msg</i>
        <i class="material-icons-round text-light cursor show-onhover hover-green">keyboard_arrow_up</i>
    </div>
</template>

<script>
export default {
    name: 'ChatWidgetTrigger'
}
</script>

<style scoped>








.chat_bobble
{
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background: #053356;
    border-radius: 7px;
    cursor: pointer;
    transition: all .7s ease;
}
.chat_bobble:hover
{
    background: #0c4c7d;
}
.hover-green
{
    color: #42b983 !important;
    transition: all .3s ease; 
}

.cursor
{
    cursor: pointer;
}

.chat_bobble:hover .show-not-onhover
{
    display: none;
    transition: all .3s ease-in;
    opacity: 0;
}
.chat_bobble .show-not-onhover 
{
    display: block;
    opacity: 100;
    transition: all .3s ease-in;
}
.chat_bobble:hover .show-onhover 
{
    display: block;
    opacity: 100;
    transition: all .3s ease-in;
}
.chat_bobble .show-onhover 
{
    display: none;
    opacity: 0;
    transition: all .3s ease-in;
}
</style>
