<template>
  <div class="content_rcfirst">
    <div class="header_first">
      <h3>Webseiten Entwicklung</h3>
    </div>
    <div class="innercontent_rcfirst">
      <div class="flags">
        <div v-for="label in labels" v-bind:key="label" class="d-inline-block flags_label">
          {{ label }}
        </div>
      </div>
      <div class="rcf_inner_content">
        <p>
          Eine moderne Webseite repräsentiert das Unternehmen und informiert Kunden über Neuigkeiten. Hierbei ist der erste Eindruck sehr wichtig.
            Wir helfen dabei, Ihre seite Einzigartig zu machen und ansprechend auf den Kundenstamm zu wirken.
        </p>
        <p>
            Hierbei erstellen wir Templates für die gängigsten CMS wie Wordpress und Joomla. Bei aufwändigeren Projekten entwickeln mit Hilfe moderner
            Techniken ein ganzes Projekt auf Basis von modernen Programmiersprachen.
        </p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "RightContentSecond",
  data(){
    return{
      labels: ["WORDPRESS","JOOMLA","JAVASCRIPT", "HTML", "CSS", "SEO"]
    }
  },
  methods:{

  }
}
</script>

<style scoped>
.header_first {
  padding: 1em;
  border-bottom: 2px #455a64 solid;
}

.innercontent_rcfirst {
  padding: 1em;
}

.flags_label {
  padding: 2px  5px 2px 5px;
  color: white;
  font-weight: bold;
  margin-right: 4px;
  font-size: x-small;
  background-color: #42b983;
  border-radius: 3px;
  border: 1px #42b983 solid;
}

.rcf_inner_content {
  padding-top: 1em;
}
</style>
