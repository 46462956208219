<template>
  <div class="chat_wrapper pb-2">
    <div class="w-100 row first m-0 ps-2 pe-2 pt-3">
      <div class="col-10">
        Telefonat Vereinbaren
      </div>
      <div class="col-2 close d-flex justify-content-end" @click="this.$emit('showchat')">
        <i class="material-icons-round rotate-45">expand_more</i>
      </div>
    </div>
    <div class="col-12">
      <div class="seperator_white col-12">
      </div>
      <div v-if="contactform == 1">
        <div class="text p-3">
          <div class="time_box click_box mb-2 py-4" @click="nextStep(); setOptionalPhone(false)">
            <div class="row d-flex align-items-center justify-content-center">
              <div class="col-2 d-flex text-align-center">
                <i class="material-icons-round">phone_in_talk</i>
              </div>
              <div class="col-8">
                Rückruf vereinbaren
              </div>
              <div class="col-2 d-flex text-align-center">
                <i class="material-icons-round">chevron_right</i>
              </div>
            </div>
          </div>
          <div class="time_box click_box mt-3 py-4" @click="setStep(4); setOptionalPhone(true)">
            <div class="row d-flex align-items-center justify-content-center">
              <div class="col-2 d-flex text-align-center">
                <i class="material-icons-round">email</i>
              </div>
              <div class="col-8">
                Nachicht Schreiben
              </div>
              <div class="col-2 d-flex text-align-center">
                <i class="material-icons-round">chevron_right</i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="contactform == 2">
        <div class="text p-3 row d-flex justify-content-center align-items-center">
          <div class="col-12 col-md-5 time_box click_box mb-2 py-4 m-2" v-for="date in this.timearray"
            v-bind:key="date.id" @click="specifiedDate.day = date.date; nextStep();">
            <div class="row d-flex align-items-center justify-content-center">
              <div class="col-12">
                {{ isGivenDay(new Date(date.date)) }} <br> <span class="small">{{ getInfoString(new Date(date.date))
                  }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5 time_box click_box mb-2 py-4 m-2"
            @click="setOptionalRequestOwnTermine(true); setStep(4);">
            <div class="row d-flex align-items-center justify-content-center">
              <div class="col-12">
                Eigenes Datum vorschlagen <br> <span class="small"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="contactform == 3">
        <div class="text p-3 row d-flex justify-content-center align-items-center">
          <div class="col-12 col-md-5 time_box click_box mb-2 py-4 m-2"
            @click="nextStep(); specifiedDate.time = '8-12'">
            <div class="row d-flex align-items-center justify-content-center">
              <div class="col-12">
                8-12 Uhr<br> <span class="small">{{ getInfoString(new Date(specifiedDate.day)) }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5 time_box click_box mb-2 py-4 m-2"
            @click="nextStep(); specifiedDate.time = '12-14'">
            <div class="row d-flex align-items-center justify-content-center">
              <div class="col-12">
                12-14 Uhr<br> <span class="small">{{ getInfoString(new Date(specifiedDate.day)) }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5 time_box click_box mb-2 py-4 m-2"
            @click="nextStep(); specifiedDate.time = '14 Uhr +'">
            <div class="row d-flex align-items-center justify-content-center">
              <div class="col-12">
                nach 14 Uhr<br> <span class="small">{{ getInfoString(new Date(specifiedDate.day)) }}</span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5 time_box click_box mb-2 py-4 m-2" @click="nextStep()">
            <div class="row d-flex align-items-center justify-content-center">
              <div class="col-12">
                Beliebig<br> <span class="small">{{ getInfoString(new Date(specifiedDate.day)) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="contactform == 1234">
        <div class="text p-2">
          <div v-for="times in this.timearray" v-bind:key="times.id" class="time_box mb-2">
            <div class="row" @click="this.setDate(times.id)">
              <div class="col-8">{{ this.getDayString(new Date(times.date).getDay()) }}<br>
                <span style="font-size: small">{{ this.getInfoString(new Date(times.date)) }}</span>
              </div>
              <div class="col-4 " style="text-align: right">
                {{ new Date(times.date).getHours() + ":" + this.addTrailingZeros(new Date(times.date).getMinutes(), 2)
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="contactform == 4">
        <div class="form_chat transition p-3 pt-4" v-if="contactform">
          <input type="email" placeholder="Email" class="p-1 ps-2 light_placeholder mb-3" v-model="email" id="email"
            required /><br>
          <input type="email" :placeholder="optionalPhone ? 'Telefonummer (optional)' : 'Telefonummer'"
            class="p-1 ps-2 light_placeholder mb-3" v-model="email" id="email" :required="optionalPhone" /><br>
          <textarea v-model="message"
            :placeholder="optionalRequest ? 'Schlagen Sie einen Termin für Ihr Telefonat vor' : 'Nachicht'"
            class="p-1 ps-2 light_placeholder mt-2 mb-3" rows="6" required></textarea><br>
          <div style="text-align: center;">
            <button class="transition" @click="sendContactRequest()">Termin Vereinbaren</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./script.js">
</script>

<style scoped src="./component.css">
</style>
