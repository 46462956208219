<template>
  <div class="content_rcfirst">
    <div class="header_first">
      <h3>Umfangreiche Application</h3>
    </div>
    <div class="innercontent_rcfirst">
      <div class="flags">
        <div v-for="label in labels" v-bind:key="label" class="d-inline-block flags_label">
          {{ label }}
        </div>
      </div>
      <div class="rcf_inner_content">
        <p>

        </p>
        <p>
            Machen Sie Ihr Unternehmen zukunftsfähig und bleiben Sie an der Spitze mit unserer Anwendungsentwicklung! Wir bieten Ihnen kreative und zuverlässige Lösungen, die Ihre Geschäftsprozesse optimieren und Ihre Kunden begeistern werden. Von der Konzeption bis zur Implementierung begleiten wir Sie auf Ihrem Weg zum Erfolg. Lassen Sie uns gemeinsam Ihre Ideen zum Leben erwecken und Ihre Visionen verwirklichen.
        </p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "RightContentThird",
  data() {
    return {
      labels: ["PHP", "VUE.JS", "SOAP/REST","ANGULAR", "JAVA SPRING"]
    }
  },
}
</script>

<style scoped>
.header_first {
  padding: 1em;
  border-bottom: 2px #455a64 solid;
}

.innercontent_rcfirst {
  padding: 1em;
}

.flags_label {
  padding: 2px  5px 2px 5px;
  color: white;
  font-weight: bold;
  margin-right: 4px;
  font-size: x-small;
  background-color: #42b983;
  border-radius: 3px;
  border: 1px #42b983 solid;
}

.rcf_inner_content {
  padding-top: 1em;
}
</style>
