<script setup>

</script>

<template>
  <div class="overlay">
    <div class="popupclose">
      <div class="container">
      <div class="popup_header">
        <h1>Aussetzung des Betriebs ab 01.07.2023</h1>
      </div>
      <div class="popup_body">
        Aufgrund einer persönlichen Weiterentwicklung und der damit verbundenen Zeitlichen Einschränkung, setzen wir den Betrieb bis auf weiteres ab dem 01.07.2023 aus. <br>
        Ich werde weiterhin beschränkt per Email erreichbar sein. Dazu bitte eine Email an daniel@ottnec.de schicken.<br><br>
        Mit freundlichen Grüßen<br>
        Inhaber Daniel Ottmann<br><br>
        <i>zum schließen irgendwo klicken</i>
      </div>
        </div>
    </div>
  </div>
</template>

<style scoped>
  .overlay{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 100;
    background-color: rgba(1, 37, 65, 0.56);
  }
  .popupclose{
    width: 100%;
    margin-top: 15%;
    background-color:white;
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: #ef0000 solid 4px;
    border-top: #ef0000 solid 4px;
  }
  .popup_body{
    font-size: larger;
  }
</style>
