<div class="team">

    <div class="container">
        <h3>Das bin ich</h3>
        <div class="underline">
        </div>
        <div class="row" style="margin-top: 2em">
            <div class="col-4">
                <img :src="daniel">
            </div>
            <div class="col-8 daniel_text">
                <span class="highlight_themed">Name:</span> Daniel Ottmann<br>
                <span class="highlight_themed">Skills:</span> PHP, VueJS, Angular, Python, HTML, CSS, JS, JAVA, Kotlin<br>
                <span class="highlight_themed">Position:</span> Inhaber<br>
                <span class="highlight_themed">Erfahrung:</span> 8 Jahre<br>
                <span class="highlight_themed">Bereiche:</span> Web- / App-Development<br>
                <span class="highlight_themed">Hobbys:</span> Skifahren, Coden, Motorrad fahren, Reisen<br>
            </div>
        </div>
    </div>
</div>
