<template>
  <PopUpClose v-if="showpopup" @click="showpopup=false;"/>
  <transition>
    <div v-if="loading" class="loading">Loading&#8230;</div>
  </transition>
  <HeaderLayout />
  <router-view>
  </router-view>
  <SiteFooter />
  <div class="contdown_wrapper">
    <div id="countdown" class="countdown" animated>

    </div>
  </div>
 <ChatWidgetTrigger v-if="!showChat" @showchat="showChat = !showChat" />
  <ChatWidget v-if="showChat" @showchat="showChat = !showChat" />
</template>

<script>
import HeaderLayout from "./components/layout/HeaderLayout.vue"
import SiteFooter from "@/components/layout/SiteFooter/SiteFooter";
import ChatWidget from "@/components/layout/ChatWidget/ChatWidget";
import ChatWidgetTrigger from "./components/layout/ChatWidgetTrigger.vue";
import PopUpClose from "@/components/layout/popup/PopUpClose.vue";


export default {
  name: 'App',
  components: {
    PopUpClose,
    ChatWidget,
    SiteFooter,
    ChatWidgetTrigger,
    HeaderLayout
  },
  data() {
    return {

      countdown: 1,
      timeSinceVisit: 0,
      interval: null,
      showChat: false,
      credentials: {
        email: "daniel@kl-ottmann.de",
        password: "*****",
      },
      showpopup: false
    }
  },
  methods: {


  },
  mounted() {
    //this.onLogin();

    //this.loginUser();
    //Check Login and Login

    this.interval = setInterval(() => {
      if (this.countdown == 1) {
        clearInterval(this.interval);
        this.showChat = true;
      }
      this.countdown = this.countdown - 1;
      document.getElementById('countdown').setAttribute("style", "height: " + this.countdown / 20 * 100 + "%");
      //console.log("prozent: " + this.countdown / 200 * 100 + "%")
    }, 1000);
  }
}
</script>

<style>
@import "@/overall.css";
@import '~bootstrap/dist/css/bootstrap.css';
@import '~animate.css/animate.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';


.timer {
  padding: 3px;
  position: fixed;
  right: 4px;
  bottom: 4px;
  color: #42b983;
  border-radius: 10px;
  border: none;
  font-size: smaller;
}

.countdown {
  transition: height 1300ms;
  background-color: #ef0000;
  position: fixed;
  height: 100%;
  width: 5px;
  right: 0px;
  bottom: 0px;
}
</style>
