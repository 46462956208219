<template>
  <div class="navitem">
    <div class="innerNav" @click="route">{{ this.item.text }}</div>
  </div>

</template>

<script>
export default {
  name: "NavItem",
  props: {
    item: {
      text: String,
      link: String
    }
  },
  methods:{
    route: function(){
      this.$router.push(this.item.link)
    }
  }
}
</script>

<style scoped>
.center {
  margin-top: auto;
  margin-bottom: auto;
}

.navitem {
  display: inline-flex;
  width: fit-content;
  height: 100%;
  padding-right: 5px;
}

.innerNav {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1em;
  padding: 1em;
  color: #455a64;
  transition: 0.5s;
  border: 0px #455a64 solid;
  border-radius: 5px;
}

.innerNav:hover {
  cursor: pointer;
  background: #455a64;
  color: white;
  border: 0px #455a64 solid;
  border-radius: 5px;
}
</style>
