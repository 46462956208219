<template>
  <div class="content_rcfirst">
    <div class="header_first">
      <h3>App Entwicklung</h3>
    </div>
    <div class="innercontent_rcfirst">
      <div class="flags">
        <div v-for="label in labels" v-bind:key="label" class="d-inline-block flags_label">
          {{ label }}
        </div>
      </div>
      <div class="rcf_inner_content">
        <p>
          Ihre eigene App ist das Aushängeschild für Ihre digitale Firma. Leichter können Kunden an eigene Produkte und das eigene Unternehmen
            kaum gebunden werden.!
        </p>
        <p>
          Mit modernen Tools wie Flutter oder Cordova lassen sich schnell Plattformübergreifende Apps entwickeln, die
          auf Android und IOS gleichermaßen funktionieren.
        </p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "RightContent",
  data() {
    return {
      labels: ["KOTLIN", "FLUTTER", "CORDOVA"]
    }
  },
}
</script>

<style scoped>
.header_first {
  padding: 1em;
  border-bottom: 2px #455a64 solid;
}

.innercontent_rcfirst {
  padding: 1em;
}

.flags_label {
  padding: 2px  5px 2px 5px;
  color: white;
  font-weight: bold;
  margin-right: 4px;
  font-size: x-small;
  background-color: #42b983;
  border-radius: 3px;
  border: 1px #42b983 solid;
}

.rcf_inner_content {
  padding-top: 1em;
}
</style>
