<div class="modal_overlay_impressum">
    <div class="container">
        <div class="impressum_text">
            <h3>IMPRESSUM</h3>
            <br>
            Angaben gemäß § 5 TMG
            <br><br>
            Daniel Ottmann<br>
            Fellhornstraße 14<br>
            87561 Oberstdorf<br><br>

            Kontakt:<br>
            Email: daniel@ottsoft.de<br>
            Tel: +49 8322 3005931<br>
            <br><br>
            <div @click="this.$emit('show')" class="kontakt_botton">
                <button class="kontakt-botton-inner">Close
                </button>
            </div>
            <br><br>
        </div>
    </div>
</div>
