<template>
  <div class="container">
    <div class="box_tipping">
      <span v-html="text_tipping"></span><span v-if="showcurser">|</span>
    </div>
    <KontactShow v-if="!showcurser" />
    <br><br>
  </div>
</template>

<script>
import KontactShow from "@/components/layout/KontactShow/KontactShow";
export default {
  name: "TippingBox",
  components: {KontactShow},
  data() {
    return {
      text: "Ein Servus aus dem Allgäu,#" +
          "#" +
          "Wir halten viel von einer gegenseitigen Vorbereitung auf das erste Treffen.#" +
          "DEsha-lb würden wir den Erstkontakt gerne per E-Mail mit ihnen aufnehmen. So lässt sich leichter definieren, die das erste Gespräch abklauben soll.#" +
          "Also schreibe uns doch ganz unverbindliche eine E-Mail mit einer kleinen Beschreibung des Projektes.##" +
          "@daniel@ottnec.de&",
      interval: null,
      curser: null,
      showcurser: true,
      akIndex: 0,
      text_tipping: ""
    }
  },
  mounted() {
    this.interval = setInterval(
        () => {
          if (this.akIndex < this.text.length) {
            if (this.text[this.akIndex] == "@") this.text_tipping += "<span style='color:#42b983'>"
            else if (this.text[this.akIndex] == "&") this.text_tipping += "</span>"
            else if (this.text[this.akIndex] == "#") this.text_tipping += "<br>"
            else this.text_tipping += this.text[this.akIndex]
            console.log("interval infinity")
            this.akIndex++
          } else this.showcurser = false;
        }, 50
    )

  },
  beforeUnmount() {
    clearInterval(this.interval)
  }
}
</script>

<style scoped>
.box_tipping {
  color: white;
  margin-top: 4em;
  width: 100%;
  padding: 3em;
  background-color: #012541;
  border-radius: 2em;
  border: none;
  margin-bottom: 5em;
  font-size: large;
}
</style>
