<template src="./sitefooter.html">

</template>

<script>
import ImpressumView from "@/components/ImpressumView/ImpressumView";

export default {
  name: "SiteFooter",
  components:{
    ImpressumView
  },
  data(){
    return{
      showImpressum : false
    }
  },
  methods:{
    disable: function(){
      this.showImpressum = false;
    },
    enable: function (){
      this.showImpressum = true
    }
  }
}
</script>

<style src="./sitefooter.css" scoped>

</style>
