<template>
 <div class="content_second">
   <div class="container">
     <h3>Bildschirm Anzeigesystem</h3>
     <div class="underline"></div>
     <AdCard
         :showPictureBig="showPicture"
         @update:showPictureBig="value => displayPicture(value)"
     />
     <KontactShow />

     <transition>
     <PictureModal v-if="showPictureBig" :show="this.pivture"
                   @show="disable"
     />
     </transition>
   </div>
 </div>
</template>

<script>
import AdCard from "@/components/secondpage/AdCard/AdCard";
import KontactShow from "@/components/layout/KontactShow/KontactShow";
import PictureModal from "@/components/layout/PictureModal.vue";
export default {
  name: "SecondPage",
  components:{
    AdCard, KontactShow, PictureModal
  },
  data(){
    return{
      pivture : null,
      showPictureBig: false
    }
  },
  methods: {
    displayPicture: function(id){
      this.pivture = id;
      this.showPictureBig = true;
    },
    disable: function(){
      this.showPictureBig = false;
    }
  }
}
</script>

<style src="./secondpage.css" scoped>

</style>
