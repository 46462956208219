<template>
  <div class="content_first">
    <div class="container">
      <div class="row" style="padding-bottom: 3em;">
        <div class="col-md-3 ">
          <div class="row" style="padding: 0em 1em 0em 1em">
            <LeftSelector @changeView="this.changeView(1)" :actual="'Web'" :isActive="counter === 1 ? true : false"/>
            <LeftSelector @changeView="this.changeView(2)" :actual="'App'" :isActive="counter === 2 ? true : false"/>
            <LeftSelector @changeView="this.changeView(0)" :actual="'Mobile'" :isActive="counter === 0 ? true : false"/>
          </div>
        </div>
        <div class="col-md-9 col-sm-12" style="">
          <transition mode="out-in">
            <RightContentFirst v-if="counter === 0"/>
            <RightContentSecond v-else-if="counter === 1"/>
            <RightContentThird v-else-if="counter === 2"/>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftSelector from "@/components/firstpage/LeftSelector.vue";
import RightContentFirst from "@/components/firstpage/RightContentFirst.vue";
import RightContentSecond from "@/components/firstpage/RightContentSecond.vue";
import RightContentThird from "@/components/firstpage/RightContentThird.vue";

export default {
  name: "FirstPage",
  data() {
    return {
      counter: 0,
      first_selectr: true,
      second_selector: false,
      slider: null
    }
  },
  components: {
    LeftSelector, RightContentFirst, RightContentSecond, RightContentThird
  },
  methods: {
    changeView: function (index) {
      this.destroySlider();
      this.counter = index;
      this.setSlider(6000);
    },
    destroySlider: function () {
      clearInterval(this.slider)
    }
    ,
    setSlider: async function (timeout) {
      this.slider = await setInterval(() => {
        this.counter == 2 ? this.counter = 0 : this.counter++
      }, timeout)
    }
  },
  async created() {
    this.setSlider(4000);
  },
  beforeUnmount() {
    this.destroySlider();
  }
}
</script>

<style scoped>
.content_first {
  width: 100%;
  height: 500px;
  background-image: url("@/assets/img/oberstdorf.jpg");
  background-size: cover;

}

.row > div {
  padding: 10px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.content_rcfirst {
  margin-top: 3em;
  height: 90%;
  width: 80%;
  background-color: #eeeeee !important;
  border: 0px white solid;
  border-radius: 10px;
  color: #455a64;
  padding: 1em;
  box-shadow: rgba(0, 0, 0, 0.55) 0px 5px 15px;
}

@media (max-width: 768px) {
  .content_rcfirst {
    width: 100%;
    margin-top: 0em;
  }
}

</style>