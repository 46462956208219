
export default {
  name: "ChatWidget",
  data() {
    return {
      timearray: [
        {
          id: 1,
          date: "2022-07-06T12:35:00"
        },
        {
          id: 2,
          date: "2022-07-07T13:45:00"
        },
        {
          id: 3,
          date: "2022-07-22T16:45:00"
        }
      ],
      contactform: 1,
      time_id: null,
      email: null,
      phone: null,
      message: null,
      specifiedDate: {
        day: null,
        time: null,
      }
    }
  },
  methods: {
    isGivenDay: function ($date)
    {
      const today = new Date();
      let result = $date.getDate() == today.getDate() &&
        $date.getMonth() == today.getMonth() &&
        $date.getFullYear() == today.getFullYear();
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      if (tomorrow.toDateString() === $date.toDateString()) {
        return "Tomorrow";
      }
      if (!result) {
        return this.getDayString($date.getDay())
      }
      return "Today";
    },
    isInPast: function ($date)
    {
      const today = new Date();
      if (today.getDate() > $date.getDate()) {
        return true;
      }
      return false;
    },
    setStep: function (step = 1)
    {
      this.contactform = step;
    },
    nextStep: function (step = 1)
    {
      this.contactform += step;
    },
    setOptionalPhone(boo = true)
    {
      this.optionalPhone = boo;
    },
    setOptionalRequestOwnTermine(boo = true)
    {
      this.optionalRequest = boo;
    },
    setDate: function (id)
    {
      this.time_id = id;
      this.contactform = !this.contactform;
    },
    sendContactRequest: function ()
    {
      if(this.email != null && this.phone != null && this.message != null) console.log("ok");
      else console.log("not ok");

    },
    getDayString: function(date) {
      switch (date) {
        case 1:
          return "Montag"
        case 2:
          return "Dienstag"
        case 3:
          return "Mittwoch"
        case 4:
          return "Donnerstag"
        case 5:
          return "Freitag"
        case 6:
          return "Samstag"
        case 7:
          return "Sonntag"
      }
    },
    getMonthString: function (date) {
      switch (date) {
        case 1:
          return "Januar"
        case 2:
          return "Februar"
        case 3:
          return "März"
        case 4:
          return "April"
        case 5:
          return "Mai"
        case 6:
          return "Juni"
        case 7:
          return "Juli"
        case 8:
          return "August"
        case 9:
          return "September"
        case 10:
          return "Oktober"
        case 11:
          return "November"
        default:
          return "Dezember"
      }
    },
    getInfoString: function(date)
    {
      let day = date.getDay();
      let month = this.getMonthString(date.getMonth());
      let year = date.getFullYear();
      return "den, " + day + ". " + month + ". " + year;
    },
    addTrailingZeros: function(num, totalLength)
    {
      if (num < 10) return String(num).padStart(totalLength, '0');
      return String(num).padEnd(totalLength, '0');
    }
  },
  mounted() {
  }
}