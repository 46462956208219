<template>
  <div class="col-12 d-none d-md-block">
    <div v-if="!isActive" @click="this.$emit('changeView')" class=" svg_first svg mx-auto bg_white">
      <img v-if="actual === 'Mobile' " src="@/assets/img/001-android.svg">
      <img v-else-if="actual === 'Web'" src="@/assets/img/002-website.svg">
      <img v-else-if="actual === 'App'" src="@/assets/img/003-software.svg">
    </div>
    <div v-if="isActive" @click="this.$emit('changeView')" class=" svg_first svg mx-auto bg_active">
      <img v-if="actual === 'Mobile' " src="@/assets/img/001-android_white.png">
      <img v-else-if="actual === 'Web'" src="@/assets/img/002-website_white.png">
      <img v-else-if="actual === 'App'" src="@/assets/img/003-software_white.png">
    </div>
  </div>
  <div @click="this.$emit('changeView')" class="d-md-none svg_mobile svg mx-auto"
       :class="[ isActive ? 'bg_active' : 'bg_white' ]">
    <div class="col-12 inner_mobile_nav">
      <p v-if="actual === 'Mobile'">ANDROID</p>
      <p v-if="actual === 'Web'">WEBSEITE</p>
      <p v-if="actual === 'App'">SOFTWARE</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftSelector",
  props: {
    isActive: Boolean,
    actual: String
  },
  data() {
    return {}
  },
  methods() {

  }
}
</script>

<style scoped>
.svg_first {
  border-radius: 50%;
  width: 30%;
  border: 3px solid #455a64;
  background-color: #eeeeee;
  margin-top: 3em;
  transition: 0.5s all;
}

.inner_mobile_nav{
  margin: auto;
}

.svg_mobile{
  display: flex;
  text-align: center;
  border-radius: 10px;
  background-color: #eeeeee;
  border: 0px solid #455a64;
  width: 90%;
}

.bg_white {
  width:30%;
  background-color: #eeeeee;
  box-shadow: rgba(0, 0, 0, 0.55) 0px 5px 15px;

}

.bg_active {
  width: 35%;
  color: #eeee;
  font-weight: bold;
  background-color: #455a64;
  box-shadow: rgba(0, 0, 0, 0.55) 0px 5px 15px;
}

.svg img {
  width: 100%;
  padding: 25%;
}

.svg_first:hover {
  width: 35%;
  border: 5px solid #455a64;
  background-color: #455a64;
  cursor: pointer;
}



</style>
