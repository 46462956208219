<template>
  <div class="row">
    <div class="col-md-4">
      <div class="card">
        <div class="card-img" @click="this.$emit('update:showPictureBig', img1)">
          <ImageLoader url="display_mgmt_inroom" />
        </div>
        <div class="card-body">
          <h5 class="card-title">Auf jedem Gerät</h5>
          <p class="card-text">Sobald das Endgerät einen Browser und eine aktive Internetverbindung besitzt, kann es nahtlos in unser System eingebunden werden.
          Möglich auf Android-, Windows-, Linux- und Mac- Geräten. Darunter fallen auch Mini-PC's wie ein Fire-TV Stick</p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <div class="card-img" @click="this.$emit('update:showPictureBig', img2)">
          <ImageLoader url="display_start" />
        </div>
        <div class="card-body">
          <h5 class="card-title">Verwaltung von jedem Gerät</h5>
          <p class="card-text">Verwalte deine Geräte einfach von jedem Browser aus. Die Bildschirmgröße und das Betrienbssystem sind dabei egal.</p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <div class="card-img" @click="this.$emit('update:showPictureBig', img3)">
          <ImageLoader url="display_mgmt" />
        </div>
        <div class="card-body">
          <h5 class="card-title">Funktionen</h5>
          <p class="card-text">
            - Bild / Video / JS Animation<br>
            - Timeline<br>
            - Diashow<br>
            - Live-Update<br>
            - unterschiedliche Auflösungen<br>
            - individualisierbar
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from "../../../assets/img/display_mgmt_inroom.png"
import img2 from "../../../assets/img/display_start.png"
import img3 from "../../../assets/img/display_mgmt.png"
import ImageLoader from "@/components/layout/ImageLoader";


export default {
  name: "AdCard",
  components: {ImageLoader},
  props: {
    showPictureBig : Number
  },
  data(){
    return{
      img1 : img1,
      img2:img2,
      img3:img3
    }
  }
}
</script>

<style src="./card.css" scoped>

</style>
