<script setup>
import { PortableText } from '@portabletext/vue';
import KontactShow from "@/components/layout/KontactShow/KontactShow.vue";
</script>

<template>
  <TippingReferenz></TippingReferenz>
  <div v-for="post in posts" v-bind:key="post._key">
    <div class="funandsnow">
      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <img :src="getURL(post.image)">
          </div>
          <div class="col-md-7">
            <span class="highlight_themed">Projekt:</span> {{post.name}}<br>
            <span class="highlight_themed">Dauer:</span> {{ post.duration }}<br>
            <span class="highlight_themed">Techstack:</span> {{post.techstack}}<br>
            <span class="highlight_themed"></span><br>

            <PortableText
                :value="post.description
      /* array of portable text blocks */
    "
                :components="{
      /* optional object of custom components to use */
    }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container underline"></div>
    <br>
  </div>
  <KontactShow />
</template>

<script>
import TippingReferenz from "@/components/TippingBox/TippingReferenz";
import {client} from "../../sanity.config";
import imageUrlBuilder from '@sanity/image-url';

export default {
  name: "ReferenzView",
  components: {
    TippingReferenz},
  data() {
    return {
      posts: null,
    }
  },
  methods: {
    getURL: function (tobuild){
      return imageUrlBuilder(client).image(tobuild);
    },
    fetchdata: function(){
      client.fetch('*[_type == "references"]| order(order)').then(response => {
        this.posts = response;
        console.log(this.posts[0].description);
      });
    }
  },
  mounted() {
    this.fetchdata();


  },
}
</script>

<style scoped>
.funandsnow{
  margin-bottom: 3em;
}
/deep/ img{
  width: 80%;
  margin-bottom: 0.5em;
  border-radius: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
</style>