<template>
  <div class="background_modal_trans" @click="this.$emit('show')">
        <img class="bigger" :src="show">
  </div>
</template>

<script>
export default {
  name: "PictureModal",
  props: {
    show: null
  }
}
</script>

<style scoped>
.background_modal_trans {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(75, 67, 67, 0.8);
  text-align: center;
}

img.bigger {
  position: relative;
  top: 5vh;
  max-width: 90vw;
  max-height: 90vh;
  box-shadow: rgba(0, 0, 0, 0.7) 0px 5px 15px;
}
</style>
