<template src="./impressum.html">

</template>

<script>
export default {
  name: "ImpressumView"
}
</script>

<style  src="./impressum.css" scoped>

</style>
