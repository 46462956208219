<script>
import { ref } from "vue";
export default {
    name: 'LoadingComponent',
    components: {},
    data() {
        return {
            load_progress_percent: ref(0),
            load_mode: ref(0), //0 = endlose animation | 1 = proggress anzeige mit animation | 2 = progress anzeige ohne extra animation
        }
    },
    methods: {
        loadUpdate()
        {
            //just as test
            this.load_progress_percent = this.load_progress_percent + (this.load_progress_percent < 100 ? 2 : 0);
            //for production
            Array.from(document.getElementsByClassName('progress_txt')).forEach((f) => {
                f.textContent = this.load_progress_percent + "%"
            });
            if (this.load_mode > 0)
            {
                Array.from(document.getElementsByClassName('progress_circle')).forEach((c) => {
                    c.setAttribute("style", ("stroke-dashoffset:" + (360-(this.load_progress_percent*3.6))+"; stroke: rgb(27, 230, 27);"));
                });
            }
        },
    },
    mounted() {
        let interval = setInterval(() => {
            this.loadUpdate();
            if(this.load_progress_percent >= 100) clearInterval(interval);
        }, 600);
    }
};
</script>

<template>
    <div class="container d-flex justify-content-center align-items-center">
        <svg id="svg">
            <circle class="bg" cx="57" cy="57" r="52" />
            <circle v-bind:class="(this.load_mode < 2) ?'after_loader':'vg'" cx="57" cy="57" r="52" />
            <circle v-bind:class="(this.load_mode < 2) ?'before_loader':'vg'" cx="57" cy="57"
                r="52" />
            <circle v-bind:class="(this.load_mode == 0) ?'animation_loader':'vg'" class="progress_circle" cx="57"
                cy="57" r="52" />
            <text class="progress_txt" x="50%" y="50%" text-anchor="middle" stroke="rgba(27, 230, 27, 0.89)"
                stroke-width="2px" dy=".3em">%</text>
        </svg>
    </div>
</template>

<style>

svg {
    width: 114px;
    height: 114px;
    margin: 1em;
}

.bg {
    fill: none;
    stroke-width: 10px;
    stroke: transparent;
}

.vg {
    fill: none;
    stroke-width: 10px;
    stroke-linecap: round;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: all .3s ease-in-out;
    stroke-dasharray: 360;
}

.animation_loader {
    fill: none;
    stroke-width: 10px;
    stroke-linecap: round;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke:transparent;
    stroke-dasharray: 360;
    stroke-dashoffset: 0;
    animation: progress-2 2.9s infinite;
    animation-delay: .05s;
    transition: all .3s ease-in-out;
}

.before_loader {
    fill: none;
    stroke-width: 10px;
    stroke-linecap: round;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke: transparent;
    stroke-dasharray: 360;
    stroke-dashoffset: 0;
    animation: progress-3 2.9s infinite;
    animation-delay: .1s;
    transition: all .3s ease-in-out;
}

.after_loader {
    fill: none;
    stroke-width: 10px;
    stroke-linecap: round;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke: transparent;
    stroke-dasharray: 360;
    stroke-dashoffset: 0;
    animation: progress-3 2.9s infinite;
    animation-delay: 0s;
    transition: all .3s ease-in-out;
}

@keyframes progress-2 {
    0% {
        stroke: rgb(27, 230, 27);
        stroke-dashoffset: 360;
    }

    100% {
        stroke: rgb(62, 232, 62);
        stroke-dashoffset: -360;
    }
}

@keyframes progress-3 {
    0% {
        stroke: rgba(202, 222, 216, 0.5);
        stroke-dashoffset: 360;
    }

    100% {
        stroke: rgba(202, 222, 216, 0.5);
        stroke-dashoffset: -360;
    }
}
</style>