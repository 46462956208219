<template>
  <FirstPage />
  <SecondPage />
  <ThirdPage />
  <TeamPage />
  <KontactShow />
</template>

<script>
import FirstPage from "@/components/layout/FirstPage";
import SecondPage from "@/components/layout/SecondPage/SecondPage";
import ThirdPage from "@/components/layout/ThirdPage/ThirdPage";
import TeamPage from "@/components/layout/TeamPage/TeamPage";
import KontactShow from "@/components/layout/KontactShow/KontactShow";

export default {
  name: "HomeView",
  components: { KontactShow, TeamPage, ThirdPage, SecondPage, FirstPage},
  data(){
    return{
      loading: true,
    }
  },
  mounted() {
    //Fake Loader faking not existing background tasks
    setTimeout(() => {
      this.loading = false;
    },1000)
  }

}
</script>

<style scoped>

</style>
