<script>
import LoadingComponent from './LoadingComponent.vue';

export default {
    name: 'LoadingPopupComponent',
    components: {
        LoadingComponent
    },
};
</script>

<template>
    <div class="container pt-5 mt-5 d-flex justify-content-center align-items-center">
        <LoadingComponent></LoadingComponent>
    </div>
    <div class="container pt-5 mt-5">
        <div class="row d-flex justify-content-center pt-5 mt-5">
            <div class="col-12 col-md-7 p-5">
                <div class="container loadingbox p-5 d-flex align-items-center justify-content-center">
                    <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Popup Loading
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border border-secondary^d-flex justify-content-center align-items-center">
                <div class="modal-body p-5 m-5">
                    <LoadingComponent></LoadingComponent>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.loadingbox
{
    border-radius: 5px;
    border: 1px solid black;
}
</style>