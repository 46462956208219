import { createRouter, createWebHistory } from 'vue-router'
import HomeView from "@/components/HomeView";
import ReferenzView from "@/components/ReferenzView";
import TestView from "@/components/TestView";
import LoadingPopupComponent from "@/components/testing/ladebalken/LoadingPopupComponent.vue";
import KontaktView from "@/components/KontaktView/KontaktView";

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView
    },
    {
        path: '/referenzen',
        name: 'Referenzen',
        component: ReferenzView
    },
    {
        path: '/kontakt',
        name: 'Kontakt',
        component: KontaktView
    },
    {
        path: '/test',
        name: 'Test',
        component: TestView
    },
    {
        path: '/loading',
        name: 'loading',
        component: LoadingPopupComponent
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
export default router
