<div class="content_third">
    <div class="container">
        <h3>Ablauf</h3>
        <div class="underline">
        </div>
        <div class="content_third_inner_box">
            <div class="row">
                <div class="col-md-4 text_third_1">
                    <div class="row">
                        <div class="col-2 third_numbercircle">
                            1:
                        </div>
                        <div class="col-10">
                            <div style="margin-left: 1em">
                                Beratung<br>
                                Angebot<br>
                                Panung
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 text_third_1">

                    <div class="row">
                        <div class="col-2 third_numbercircle">
                            2:
                        </div>
                        <div class="col-10">
                            <div style="margin-left: 1em">
                                Entwicklung<br>
                                Bug-Fixing<br>
                                Übergabe
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 text_third_1">
                    <div class="row">
                        <div class="col-2 third_numbercircle">
                            3:
                        </div>
                        <div class="col-10">
                            <div style="margin-left: 1em">
                                Hosting<br>
                                Wartung<br>
                                Weiterentwicklung
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


