<template>
  <div class="navbar">
    <div class="container">
      <div class="row" style="width: 100%;">
        <div class="col-md-4 col-10"><img src="../../assets/img/OttSoft_little_nt.png" /></div>
        <div class="d-none d-md-block col-md-8 " style="text-align: right">
          <NavItem v-for="item in menue" v-bind:key="item.text" :item="item" />
        </div>
        <div class="col-2 d-md-none mobile-burger">
          <i @click="toggleMobile()" class="bi-list"></i>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showMenue">
    <div @click="toggleMobile(m.link)" v-for="m in menue" v-bind:key="m.text" class="item_mobile_menue">
      {{m.text}}
    </div>
  </div>
</template>
<script>
import NavItem from "@/components/layout/NavItem";

export default {
  nme: "HeaderLayout",
  components:{
    NavItem
  },
  data(){
    return{
      showMenue: false,
      menue: [
        {
          text: "Startseite",
          link: "/"
        },
        {
          text: "Referenzen",
          link: "/referenzen"
        },
        {
          text: "Kontakt",
          link: "/kontakt"
        },
      ]
    }
  },
  methods:{
    toggleMobile: function (link){
      this.showMenue = !this.showMenue;
      this.$router.push(link)
    }
  }
}
</script>

<style scoped>
  .navbar{
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  img{
    width: 80%;
    padding: 1em;
  }
  ul{
    list-style-type: none;
    width: 100%;
  }
  a{
    text-decoration: none;
  }
  i{
    font-size: xxx-large;
    cursor: pointer;
    color: black;
  }
  .mobile-burger{
    padding-top:1em;
    padding-bottom:1em;
    height: 100%;
    text-align: right;
    vertical-align: middle;
  }
  .item_mobile_menue{
    width: 100%;
    padding: 0.8em;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.20) 0px 5px 15px;
  }
  .item_mobile_menue a{
    text-decoration: none;
    color: #455a64;
  }
</style>
