<ImpressumView v-if="showImpressum" :show="showImpressum" @show="disable" />
<div class="footer">
    <div class="container">
        <div class="row">
            <div class="col-md-4 footer_first">
                <h4>Brands:</h4>
                <ul>
                    <li>Hosting-B2B: <a href="https://hosterhoster.de">hosterhoster.de</a> </li>
                    <li>Hosting-B2C: <a href="https://hosting.ottnec.de">hosting.ottnec.de</a> </li>
                    <li>Software: <a href="https://ottsoft.de">ottsoft.de</a> </li>
                    <li>Software: <a href="https://wiobu.de">wiobu.de</a> </li>
                    <li>Hardware: <a href="https://ottnec-hardware.de">ottnec-hardware.de</a> </li>
                </ul>
            </div>
            <div class="col-md-4 footer_first">
                <h5>Kontakt:</h5>
                <ul>
                    <li>
                        Tel: 08322 3005931
                    </li>
                    <li>
                        Mail: support@ottnec.de
                    </li>
                </ul>
            </div>
            <div class="col-md-4 footer_first">
                <h4>Rechtliches:</h4>
                <ul>
                    <li><a @click="enable" href="#">Impressum</a></li>
                    <li>Datenschutz</li>

                </ul>
            </div>
        </div>
    </div>
</div>
<div class="footer_footer">
    <center>- Made with pasion in Oberstdorf -</center>
</div>
