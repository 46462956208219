<template>
  <transition>
    <img v-show="isLoad" :src="getPath(url)" @load="loaded">
  </transition>
  <img v-show="!isLoad" :src="getSmallPath(url)">
</template>

<script>
export default {
  name: "ImageLoader",
  props: {
    url: null,
    url_small: null
  },
  data() {
    return {
      isLoad: false,
      image: ""
    }
  },
  created() {
    this.loadImg()
  },
  methods: {
    getPath(url){
      return require("@/assets/img/"+url+".png");
    },
    getSmallPath(url){
      return require("@/assets/img/"+url+"_little.png");
    },
    loadImg() {
      this.isLoad = false
      this.image = this.url
    },
    loaded() {
      setTimeout(() => {
        this.isLoad = true
      }, 500)
    }
  }
}
</script>

<style scoped>



img.v-enter, img.v-leave {
  opacity: 0;
}

</style>
