<template>
  <KontactShow></KontactShow>
  <TippingBox></TippingBox>
</template>

<script>
import TippingBox from "@/components/TippingBox/TippingBox";
import KontactShow from "@/components/layout/KontactShow/KontactShow";
export default {
  name: "KontaktView.vue",
  components: {KontactShow, TippingBox}
}
</script>

<style scoped>

</style>
