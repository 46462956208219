<template src="./team.html">

</template>

<script>
import daniel from "../../../assets/img/1618159441755.jpg"


export default {
  name: "TeamPage",
  data(){
    return{
      daniel: daniel
    }
  }
}
</script>

<style src="./team.css" scoped>

</style>
