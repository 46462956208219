<template src="./contact.html">

</template>

<script>

export default {
  name: "KontactShow",

}
</script>

<style src="./kontact.css" scoped>
</style>
