<template src="./thirdpage.html">

</template>

<script>
export default {
  name: "ThirdPage"
}
</script>

<style src="./thirdpage.css" scoped>

</style>
