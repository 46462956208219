<div class="button-kontakt">
    <div class="kontakt_text">

        <h4>Interesse?</h4>
        Wir versuchen den Kontakt zu unseren Kunden zu halten.<br>
        Deshalb <span class="highlight_themed">schreib uns doch eine Email</span>, sodass wir genau wissen, wie wir dir
        helfen können.
        <br>Für Kunden aus <span class="highlight_themed">Oberstdorf</span> oder dem <span class="highlight_themed">Allgäu</span>
        bieten wir auch einen Vor-Ort <span class="highlight_themed">Installationsservice</span> an.
    </div>
    <div class="kontakt_botton">
        <a href="mailto:support@ottnec.de?subject=REF: ottsoft.de" class="kontakt-botton-inner">Email an support@ottnec.de
        </a>
    </div>
</div>
